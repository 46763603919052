import * as React from 'react';
import Head from '../components/Head/Head';

export default function LandingPage() {
  return (
    <div id="landingPage">
      <Head
        title="The Jon Gordon NTFs"
        description="International bestselling author, Jon Gordon, is launching his first NFT project and you can claim yours for free."
      />
      <div className="top-header">
        <div className="container">
          <div className="texts">
            <h1 className="title-get-jon-gordons">GET JON GORDON{"'"}S</h1>
            <h1 className="title-first-nft">FIRST NFT</h1>
            <h1 className="title-for-free">FOR FREE!</h1>
            <p className="text">
              International bestselling author, Jon Gordon, is launching his first NFT project and
              you can claim yours for free.
            </p>
            <a href="/portal" className="button-blue">
              Get NFT Now <img src="landingPage/button-arrow.png" alt="" />
            </a>
          </div>

          <span className="image-big-coin"></span>
          <span className="shadow-blue"></span>
          <span className="shadow-yellow"></span>
        </div>
      </div>

      <div className="nfts-include">
        <div className="container">
          <div className="box-white">
            <h2>NFT{"'"}s include a chance to:</h2>
            <ul>
              <li>
                <div></div>
                <div>Attend an Exclusive Keynote Talk with Jon to a college or pro Sports Team</div>
              </li>
              <li>
                <div></div>
                <div>Have Dinner with Jon and His Wife</div>
              </li>
              <li>
                <div></div>
                <div>Have Jon Present a Keynote Talk To Your Company, Organization or Team</div>
              </li>
              <li>
                <div></div>
                <div>Have Jon Coach You One-On-One</div>
              </li>
              <li>
                <div></div>
                <div>Have one of Jon{"'"}s new books dedicated to you</div>
              </li>
              <li>
                <div></div>
                <div>Have Jon interview you on his podcast</div>
              </li>
              <li>
                <div></div>
                <div>Have Jon call your friend or relative and encourage them.</div>
              </li>
              <li>
                <div></div>
                <div>...all for FREE!</div>
              </li>
            </ul>
          </div>
          <span className="image-cube"></span>
          <span className="image-hexagon"></span>
        </div>
        <span className="image-sm-ball"></span>
        <span className="image-md-ball"></span>
        <span className="image-line-curve"></span>
      </div>

      <div className="the-sale-book">
        <div className="container">
          <div className="image-book">
            <img src="landingPage/the-sales-book-cover.png" alt="The Sale Book's Cover" />
          </div>
          <div className="text">
            <p>
              These are just a few of the many utilities/valuable offerings Jon is giving away with
              his first NFT project associated with the release of his new bestselling book, {'"'}
              The Sale.{'"'}
            </p>
            <p>
              With every purchase of 11 copies of {'"'}The Sale{'"'} Jon is going to gift you an NFT
              for FREE.
            </p>
            <p>
              Every NFT will have value well worth the books however some of the NFT{"'"}s will have
              a super rare and rare value, like the items above that you can redeem. NFTs are issued
              at random so everyone will have an equal chance at getting the most valuable options
              (like any pack of baseball cards could have a valuable card within it).
            </p>
            <a href="/portal" className="button-blue">
              Get NFT Now
            </a>
          </div>
        </div>
      </div>

      <div className="how-to-get">
        <div className="container">
          <div className="box-white">
            <div className="header">
              <h2>
                Here{"'"}s How to Get Your
                <div className="box-bg-blue">
                  <span>Free NFT:</span>
                </div>
              </h2>
              <p>
                *Every purchase of 11 gets you an NFT. Buy 11 books, get 1 NFT. Buy 22 books, get 2
                NFT{"'"}s, etc.
              </p>
            </div>
            <div className="steps">
              <div className="step step1">
                <h3>Step 1</h3>
                <p>
                  Order 11 copies of {'"'}The Sale{'"'} at Amazon or Barnes & Noble
                </p>
                <div className="logos">
                  <div className="box-blue">
                    <a href="https://amzn.to/3Ll8gpB" target="_blank" rel="noreferrer">
                      <img src="landingPage/logo-amazon.png" alt="Amazon" width="70" height="70" />
                    </a>
                  </div>

                  <div className="box-blue">
                    <a
                      href="https://www.barnesandnoble.com/w/the-sale-jon-gordon/1140487498?ean=9781119762690"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="landingPage/logo-barnes-noble.png"
                        alt="Barnes & Noble"
                        width="70"
                        height="70"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="step step2">
                <h3>Step 2</h3>
                <p>Complete the confirmation form here.</p>
                <a
                  href="https://responses.formstack.com/forms/thesale_book"
                  target="_blank"
                  className="button-blue"
                  rel="noreferrer"
                >
                  Get NFT Now
                </a>
              </div>
            </div>
          </div>
          <div className="image-hand-coin"></div>
          <div className="image-line-curve"></div>
        </div>
      </div>

      <div className="webinar-replay">
        <div className="container">
          <div className="box-white">
            <div className="header">
              <h2>WATCH THE WEBINAR REPLAY</h2>
              <p>
                We actually hosted a LIVE webinar about this yesterday where I explained it in more
                detail.
              </p>
              <p>
                <a href="https://vimeo.com/701474048" target="_blank" rel="noreferrer">
                  You can watch the replay here.
                </a>
              </p>
              <img src="landingPage/webinar-jon-gordon.png" alt="Webinar with Jon Gordon" />
              <p>
                <strong>The response was tremendous!</strong>
              </p>
            </div>
          </div>
          <div className="image-button-play"></div>
        </div>
        <div className="image-md-ball"></div>
        <div className="image-line-curve"></div>
      </div>

      <div className="what-an-nft">
        <div className="container">
          <div className="header">
            <span className="image-question-mark"></span>
            <h2>
              If you{"'"}re wondering,
              <span className="blue-text">
                {'"'}What{"'"}s an NFT?,{'"'}
              </span>{' '}
              don{"'"}t worry... we{"'"}ve got you covered.
            </h2>
          </div>
          <div className="columns3">
            <div>
              We know many of you might be crypto-curious having heard of crypto currency, Bitcoin
              and NFT{"'"}s but not exactly sure what it{"'"}s all about. With our expert guides, we
              {"'"}ll help educate you along the way and make claiming your NFT as easy as it can
              be.
            </div>
            <div>
              Jumping in with us on this now also will give you access to our NFT community where
              myself and my team will be pouring into you, educating you, and helping you navigate
              the future (which is Web 3.0 today).
            </div>
            <div>
              <p>We{"'"}ll also be launching an Energy Bus NFT in the future.</p>
              <p>
                I hope you{"'"}ll take advantage of this. Be sure to watch that webinar replay. It
                will answer most of your questions but if you have more, just reply back and let us
                know.
              </p>
              <p>- Jon</p>
            </div>
          </div>
          <span className="shadow-blue"></span>
        </div>
      </div>

      <div className="roadmap">
        <div className="container">
          <div className="box-white">
            <div className="header">
              <h2>
                <span className="image-pokemon-coin"></span>
                <div className="box-bg-blue">
                  <span>Roadmap</span>
                </div>
              </h2>
            </div>
            <ul>
              <li>
                <div>
                  <div className="line">
                    <span className="image-ball-mark-up"></span>
                    <span className="number">01</span>
                    <span className="image-line-gray"></span>
                  </div>
                  <h3>The Sale</h3>
                  <p>
                    With the release of {'"'}The Sale{'"'} book, Jon Gordon offers a free NFT with
                    purchase of 11 books.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <div className="line">
                    <span className="image-ball-mark-right"></span>
                    <span className="number">02</span>
                    <span className="image-line-gray"></span>
                  </div>
                  <h3>Bestseller</h3>
                  <p>
                    {'"'}The Sale{'"'} debuts as a Wall Street Journal Bestseller.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <div className="line">
                    <span className="image-ball-mark-down"></span>
                    <span className="number">03</span>
                    <span className="image-line-gray"></span>
                  </div>
                  <h3>The Drop</h3>
                  <p>
                    On June 15, those who purchased 11 copies of {'"'}The Sale{'"'} will receive
                    their FREE NFT. NFT is provided via randomizer so each person has equal chance
                    of receiving rare or super valuable NFT utilities.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <div className="line">
                    <span className="image-ball-mark-left"></span>
                    <span className="number">04</span>
                    <span className="image-line-gray"></span>
                  </div>
                  <h3>The Reveal</h3>
                  <p>
                    On June 29 the value of the received NFT{"'"}s will be revealed! This is the day
                    you{"'"}ll know what you have and can actively trade / sell on the NFT
                    marketplace (if you wish) or begin to claim your NFT values.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <div className="line">
                    <span className="image-ball-mark-left"></span>
                    <span className="number">05</span>
                    <span className="image-line-gray"></span>
                  </div>
                  <h3>Jon Gordon / Energy Bus Metaverse</h3>
                  <p>
                    {'"'}The Sale{'"'} NFT is just the beginning of a much larger Jon Gordon /
                    Energy Bus metaverse ({'"'}The Energy Bus{'"'} is one of Jon{"'"}s most popular
                    books having sold millions of copies around the world).
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <span className="image-sm-ball"></span>
        <span className="image-md-ball"></span>
        <span className="image-line-curve"></span>
      </div>

      <div className="faq">
        <div className="container">
          <h2>FAQ</h2>

          <div className="tabs">
            <div className="tab">
              <input type="radio" id="faq-1" name="faq" />
              <label className="tab-label" htmlFor="faq-1">
                <span>What are The Sale NFTs?</span>
              </label>
              <div className="tab-content">
                Bestselling author Jon Gordon{"'"}s first (or eventually many) NFT releases.
                Designed as a token / coin complimenting the token / coin referenced in the book,
                each NFT will have it{"'"}s own unique traits.
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="faq-2" name="faq" />
              <label className="tab-label" htmlFor="faq-2">
                <span>How much does the NFT cost?</span>
              </label>
              <div className="tab-content">
                100% free when you purchase 11 copies of Jon{"'"}s bestselling book {'"'}The Sale
                {'"'}.
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="faq-3" name="faq" />
              <label className="tab-label" htmlFor="faq-3">
                <span>How do I claim my free NFT?</span>
              </label>
              <div className="tab-content">
                Purchase 11 (or more) copies of {'"'}The Sale{'"'} book anywhere books are sold and
                complete the claim form at
                <a href="https://thesalebook.com/" target="_blank" rel="noreferrer">
                  TheSaleBook.com
                </a>
                - You get (1) free NFT for every 11 books purchased. Want 1 free NFT? Purchase 11
                books. Want 5 free NFT{"'"}s? Purchase 55 books.
              </div>
            </div>
            <div className="tab">
              <input type="radio" id="faq-4" name="faq" />
              <label className="tab-label" htmlFor="faq-4">
                <span>When can I claim my NFT?</span>
              </label>
              <div className="tab-content">We plan to mint the NFTs in late June, 2022.</div>
            </div>
            <div className="tab">
              <input type="radio" id="faq-5" name="faq" />
              <label className="tab-label" htmlFor="faq-5">
                <span>What can I do with my NFT?</span>
              </label>
              <div className="tab-content">
                Every NFT will have value. Some (valued assigned randomly so that everyone has a
                fair chance) will be redeemable for special values including:
                <ul>
                  <li>Attend an Exclusive Keynote Talk with Jon to a college or pro Sports Team</li>
                  <li>Have Dinner with Jon and his Wife</li>
                  <li>Have Jon Present a Keynote Talk To Your Company, Organization or Team</li>
                  <li>Have Jon Coach You One-On-One</li>
                  <li>Have one of Jon{"'"}s new books dedicated to you.</li>
                  <li>Have Jon Interview you on his podcast</li>
                  <li>Jon will call your friend or relative and encourage them.</li>
                </ul>
                Every NFT holder will receive exclusive insider access to special opportunities,
                first release of future NFTs and access to our NFT community (including Discord
                Chat, monthly chats, updates from NFT experts, etc).
              </div>
            </div>
          </div>
          <div className="faq-need-help">
            <a
              href="https://responses.formstack.com/forms/jgnft"
              target="_blank"
              style={{ fontSize: '14px' }}
              rel="noreferrer"
            >
              Need Help? Ask Questions Here.
            </a>
          </div>
        </div>
      </div>

      <div className="about">
        <div className="container">
          <div className="box-white">
            <div>
              <div className="header">
                <div className="title">
                  <h2>ABOUT</h2>
                  <h2>JON GORDON</h2>
                </div>
                <p>
                  Jon Gordon{"'"}s best-selling books and talks have inspired readers and audiences
                  around the world. His principles have been put to the test by numerous Fortune 500
                  companies, professional and college sports teams, school districts, hospitals, and
                  non-profits. He is the author of 24 books including 12 best sellers and 5 children
                  {"'"}s books. His books include the timeless classic The Energy Bus which has sold
                  over 2 million copies, The Carpenter which was a top 5 business book of the year,
                  Training Camp, The Power of Positive Leadership, The Power of a Positive Team, The
                  Coffee Bean, Stay Positive, and The Garden. Jon and his tips have been featured on
                  The Today Show, CNN, CNBC, The Golf Channel, Fox and Friends and in numerous
                  magazines and newspapers. His clients include The Los Angeles Dodgers, Campbell
                  {"'"}s Soup, Dell, Publix, Southwest Airlines, Miami Heat, The Los Angeles Rams,
                  Snapchat, Truist Bank, Clemson Football, Northwestern Mutual, West Point Academy
                  and more.
                </p>
              </div>
            </div>
            <div>
              <img
                src="landingPage/about-jon-gordon.jpg"
                // src="landingPage/bkp-about-jon-gordon.jpg"
                alt="Jon Gordon smiling"
                style={{ maxHeight: 662 }}
              />
            </div>
          </div>
          <div className="image-sm-ball"></div>
        </div>
        <div className="image-md-ball"></div>
        <div className="image-line-curve"></div>
      </div>
      <div className="last"></div>
    </div>
  );
}
