import NextHead from 'next/head';
import type { PropsWithChildren } from 'react';
import React from 'react';

type HeadProps = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  canonicalUrl?: string;
  seoIndexPage?: boolean;
  seoFollowPage?: boolean;
};

export default function Head({
  image: _image = '',
  url: path = '',
  canonicalUrl: canonical = '',
  children,
  seoIndexPage = true,
  seoFollowPage = true,
  ...props
}: PropsWithChildren<HeadProps>) {
  const { title = '', description = '' } = props;
  const siteUrl = `https://${process.env.VERCEL_URL}`;

  const image = !_image ? `${siteUrl}/og-default.png` : _image;
  const imageTwitter = `${siteUrl}/og-default-twitter.png`;
  const url = `${siteUrl}${path}`;
  const canonicalUrl = canonical || url;
  const robotsTagValue = `${seoIndexPage ? '' : 'no'}index, ${seoFollowPage ? '' : 'no'}follow`;

  // TODO: Adicionar um imagem de og-default.png
  // TODO: Adicionar essas mensagens em variáveis de ambiente
  return (
    <NextHead>
      <title key="pageTitle">{title}</title>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <link rel="canonical" href={canonicalUrl} key="canonical" />
      <meta name="robots" content={robotsTagValue} key="robotsContent" />

      <meta name="title" content={title} key="title" />
      <meta name="description" content={description} key="description" />

      <meta property="og:image" content={image} key="pageOgImage" />
      <meta property="og:url" content={url} key="pageOgUrl" />
      <meta property="og:type" content="website" key="pageOgType" />
      <meta property="og:site_name" content="The Jon Gordon NTFs" key="pageOgSiteName" />
      <meta property="og:title" content={title} key="pageOgTitle" />
      <meta property="og:description" content={description} key="pageOgDescription" />

      <meta property="twitter:card" content="summary" key="pageTwitterCard" />
      <meta property="twitter:site" content="@jongordon11" key="pageTwitterCard" />
      <meta property="twitter:title" content={title} key="pageTwitterTitle" />
      <meta property="twitter:description" content={description} key="pageTwitterDescription" />
      <meta
        property="twitter:image"
        itemProp="image"
        content={imageTwitter}
        key="pageTwitterImage"
      />
      <meta property="twitter:url" content={url} key="pageTwitterUrl" />

      {children}
    </NextHead>
  );
}
